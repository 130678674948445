<template>
  <div class="m-4">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="headerFont">{{ $t("SATCourse.winter.title") }}</span>
      </div>
      <div>
        <el-image
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/sat_part1.jpg"
          style="width: 100%; height: 100%"
        />
        <p>{{ $t("SATCourse.desc1") }}</p>
      </div>
      <el-divider></el-divider>
      <div>
        <h4>{{ $t("SATCourse.placementTest") }}</h4>
        <p>{{ $t("SATCourse.placementTestDesc") }}</p>
      </div>
      <el-divider></el-divider>
      <div>
        <h4>{{ $t("SATCourse.tailorMadeSmallGroupClasses") }}</h4>
        <p>{{ $t("SATCourse.tailorMadeSmallGroupClassesDesc1") }}</p>
        <p>{{ $t("SATCourse.tailorMadeSmallGroupClassesDesc2") }}</p>
      </div>
      <el-divider></el-divider>
      <div>
        <h4>{{ $t("SATCourse.provenLearningMethods") }}</h4>
        <p>{{ $t("SATCourse.provenLearningMethodsDesc") }}</p>
      </div>
      <el-row>
        <el-col :sm="8" style="text-align: center">
          <div>
            <div class="fa-icon">
              <i class="fas fa-exchange-alt"></i>
            </div>
            <h3>
              {{
                $t("SATCourse.provenLearningMethodsPoints.interactiveLearning")
              }}
            </h3>
            <p>
              {{
                $t(
                  "SATCourse.provenLearningMethodsPoints.interactiveLearningDesc"
                )
              }}
            </p>
          </div>
        </el-col>
        <el-col :sm="8" style="text-align: center">
          <div>
            <div class="fa-icon">
              <i class="fab fa-slideshare"></i>
            </div>
            <h3>
              {{
                $t("SATCourse.provenLearningMethodsPoints.2TeachersPerClass")
              }}
            </h3>
            <p>
              {{
                $t(
                  "SATCourse.provenLearningMethodsPoints.2TeachersPerClassDesc"
                )
              }}
            </p>
          </div>
        </el-col>
        <el-col :sm="8" style="text-align: center">
          <div>
            <div class="fa-icon">
              <i class="fas fa-tachometer-alt"></i>
            </div>
            <h3>
              {{
                $t("SATCourse.provenLearningMethodsPoints.englishFundamentals")
              }}
            </h3>
            <p>
              {{
                $t(
                  "SATCourse.provenLearningMethodsPoints.englishFundamentalsDesc"
                )
              }}
            </p>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :sm="8" style="text-align: center">
          <div>
            <div class="fa-icon">
              <i class="fas fa-history"></i>
            </div>
            <h3>
              {{
                $t(
                  "SATCourse.provenLearningMethodsPoints.dailyAfterClassReview"
                )
              }}
            </h3>
            <p>
              {{
                $t(
                  "SATCourse.provenLearningMethodsPoints.dailyAfterClassReviewDesc"
                )
              }}
            </p>
          </div>
        </el-col>
        <el-col :sm="8" style="text-align: center">
          <div>
            <div class="fa-icon">
              <i class="fas fa-comments"></i>
            </div>
            <h3>
              {{
                $t("SATCourse.provenLearningMethodsPoints.studyGroupDiscussion")
              }}
            </h3>
            <p>
              {{
                $t(
                  "SATCourse.provenLearningMethodsPoints.studyGroupDiscussionDesc"
                )
              }}
            </p>
          </div>
        </el-col>
        <el-col :sm="8" style="text-align: center">
          <div>
            <div class="fa-icon">
              <i class="fa fa-users"></i>
            </div>
            <h3>
              {{
                $t("SATCourse.provenLearningMethodsPoints.homeworkDiscussion")
              }}
            </h3>
            <p>
              {{
                $t(
                  "SATCourse.provenLearningMethodsPoints.homeworkDiscussionDesc"
                )
              }}
            </p>
          </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div>
        <h4>{{ $t("SATCourse.classSchedule") }}</h4>
        <p>{{ $t("SATCourse.classScheduleDesc1") }}</p>
        <h5 class="pt-4">{{ $t("SATCourse.classScheduleSubTitle") }}</h5>
        <p>{{ $t("SATCourse.classScheduleDesc2") }}</p>
        <el-row class="mt-5">
          <el-col :sm="8" style="text-align:center">
            <div class="fa-icon">
              <i class="fas fa-chart-pie"></i>
            </div>
            <h3>{{ $t("SATCourse.classSchedulePoints.part1") }}</h3>
            <p>{{ $t("SATCourse.classSchedulePoints.part1Desc") }}</p>
          </el-col>
          <el-col :sm="16" style="text-align:center">
            <el-tabs v-model="activeName1" type="border-card">
              <el-tab-pane :label="$t('SATCourse.scheduleA')" name="first">
                <el-image
                  src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/Winter_Part1.JPG"
                  style="width: 100%; height: 100%"
                ></el-image>
              </el-tab-pane>
              <el-tab-pane :label="$t('SATCourse.scheduleB')" name="second">
                <el-image
                  src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/Winter_Part1.JPG"
                  style="width: 100%; height: 100%"
                ></el-image>
              </el-tab-pane>
              <el-tab-pane :label="$t('SATCourse.scheduleC')" name="third">
                <el-image
                  src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/Winter_Part1.JPG"
                  style="width: 100%; height: 100%"
                ></el-image>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
        <el-row class="mt-5">
          <el-col :sm="8" style="text-align:center">
            <div class="fa-icon">
              <i class="fas fa-eye-dropper"></i>
            </div>
            <h3>{{ $t("SATCourse.classSchedulePoints.part2") }}</h3>
            <p>{{ $t("SATCourse.classSchedulePoints.part2Desc") }}</p>
          </el-col>
          <el-col :sm="16" style="text-align:center">
            <el-tabs v-model="activeName2" type="border-card">
              <el-tab-pane :label="$t('SATCourse.scheduleA')" name="first">
                <el-image
                  src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/Winter_Part2.JPG"
                  style="width: 100%; height: 100%"
                ></el-image>
              </el-tab-pane>
              <el-tab-pane :label="$t('SATCourse.scheduleB')" name="second">
                <el-image
                  src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/Winter_Part2.JPG"
                  style="width: 100%; height: 100%"
                ></el-image>
              </el-tab-pane>
              <el-tab-pane :label="$t('SATCourse.scheduleC')" name="third">
                <el-image
                  src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/Winter_Part2.JPG"
                  style="width: 100%; height: 100%"
                ></el-image>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
        <el-row class="mt-5">
          <el-col :sm="8" style="text-align:center">
            <div class="fa-icon">
              <i class="fa fa-rocket"></i>
            </div>
            <h3>{{ $t("SATCourse.classSchedulePoints.part3") }}</h3>
            <p>{{ $t("SATCourse.classSchedulePoints.part3Desc") }}</p>
          </el-col>
          <el-col :sm="16" style="text-align:center">
            <el-tabs v-model="activeName3" type="border-card">
              <el-tab-pane :label="$t('SATCourse.scheduleA')" name="first">
                <el-image
                  src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/Winter_Part3.JPG"
                  style="width: 100%; height: 100%"
                ></el-image>
              </el-tab-pane>
              <el-tab-pane :label="$t('SATCourse.scheduleB')" name="second">
                <el-image
                  src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/Winter_Part3.JPG"
                  style="width: 100%; height: 100%"
                ></el-image>
              </el-tab-pane>
              <el-tab-pane :label="$t('SATCourse.scheduleC')" name="third">
                <el-image
                  src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/Winter_Part3.JPG"
                  style="width: 100%; height: 100%"
                ></el-image>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </div>
      <el-divider></el-divider>
      <div>
        <h3>{{ $t("SATCourse.originalMaterialsOfficialPractices") }}</h3>
        <p>{{ $t("SATCourse.originalMaterialsOfficialPracticesDesc") }}</p>
        <ol>
          <li>
            {{ $t("SATCourse.originalMaterialsOfficialPracticesPoint.1") }}
          </li>
          <li>
            {{ $t("SATCourse.originalMaterialsOfficialPracticesPoint.2") }}
          </li>
          <li>
            {{ $t("SATCourse.originalMaterialsOfficialPracticesPoint.3") }}
          </li>
          <li>
            {{ $t("SATCourse.originalMaterialsOfficialPracticesPoint.4") }}
          </li>
          <li>
            {{ $t("SATCourse.originalMaterialsOfficialPracticesPoint.5") }}
          </li>
          <li>
            {{ $t("SATCourse.originalMaterialsOfficialPracticesPoint.6") }}
          </li>
          <li>
            {{ $t("SATCourse.originalMaterialsOfficialPracticesPoint.7") }}
          </li>
          <li>
            {{ $t("SATCourse.originalMaterialsOfficialPracticesPoint.8") }}
          </li>
          <li>
            {{ $t("SATCourse.originalMaterialsOfficialPracticesPoint.9") }}
          </li>
          <li>
            {{ $t("SATCourse.originalMaterialsOfficialPracticesPoint.10") }}
          </li>
        </ol>
      </div>
      <el-divider></el-divider>
      <div>
        <el-row>
          <el-col :sm="16">
            <h3>{{ $t("SATCourse.teacherStudentInteraction") }}</h3>
            <br />
            <p>{{ $t("SATCourse.teacherStudentInteractionDesc") }}</p>
          </el-col>
          <el-col :sm="8" style="text-align:center">
            <div class="fa-icon" style="font-size: 130px">
              <i class="fas fa-exchange-alt"></i>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-divider></el-divider>
      <div>
        <el-row>
          <h3>{{ $t("SATCourse.heavyWorkload") }}</h3>
          <el-col :sm="8" style="text-align:center">
            <div class="fa-icon" style="font-size: 130px">
              <i class="fas fa-chart-line"></i>
            </div>
          </el-col>
          <el-col :sm="16">
            <br />
            <p>{{ $t("SATCourse.heavyWorkloadDesc") }}</p>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName1: "first",
      activeName2: "first",
      activeName3: "first"
    };
  }
};
</script>

<style lang="scss" scoped>
.headerFont {
  font-size: 32px;
  color: #42a16a;
  font-weight: 600;
}
.fa-icon {
  color: #42a16a;
  font-size: 72px;
}
</style>
